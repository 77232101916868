import jwtDefaultConfig from './jwtDefaultConfig'

const qs = require('qs')

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()
        console.log("accessToken" + accessToken)
        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          config.headers.token = accessToken
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)

              //  this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, qs.stringify(...args))
  }
  //超管查询所有设备信息
  queryDevicesAll(...args) {
    return this.axiosIns.post(this.jwtConfig.queryDevicesAll, qs.stringify(...args))
  }
  //管理员查询名下的所有设备信息
  queryDevices(...args) {
    return this.axiosIns.post(this.jwtConfig.queryDevices, qs.stringify(...args))
  }
  //删除名下的设备
  deleteDevices(...args) {
    return this.axiosIns.post(this.jwtConfig.deleteDevices, qs.stringify(...args))
  }
  //根据ID查询微信号信息
  queryAccountsById(...args) {
    return this.axiosIns.post(this.jwtConfig.findDeviceStateInfoByid, qs.stringify(...args))
  }
  //根据客户ID查询客户公司部门
  queryByCid(...args) {
    return this.axiosIns.post(this.jwtConfig.queryByCid, qs.stringify(...args))
  }

  //根据客户id跟部门id查找客服列表
  getAllAccountInfoByTypeCidDid(...args) {
    return this.axiosIns.post(this.jwtConfig.getAllAccountInfoByTypeCidDid, qs.stringify(...args))
  }
  //更新设备信息
  updateDevice(...args) {
    return this.axiosIns.post(this.jwtConfig.updateDevice, qs.stringify(...args))
  }
  //新增设备信息
  addDevice(...args) {
    return this.axiosIns.post(this.jwtConfig.addDevice, qs.stringify(...args))
  }

  //查询所有微信好友
  queryFriendsAll(...args) {
    return this.axiosIns.post(this.jwtConfig.queryFriendsAll, qs.stringify(...args))
  }
  //根据好友id查询好友信息
  queryFriendid(...args) {
    return this.axiosIns.post(this.jwtConfig.queryFriendid, qs.stringify(...args))
  }

  //更新微信好友列表
  friendquery(...args) {
    return this.axiosIns.post(this.jwtConfig.friendquery, qs.stringify(...args))
  }
  //查询所有微信群
  queryChatRoomAll(...args) {
    return this.axiosIns.post(this.jwtConfig.queryChatRoomAll, qs.stringify(...args))
  }
  //根据群号查询群名称
  queryChatRoominfoBychatroomid(...args) {
    return this.axiosIns.post(this.jwtConfig.queryChatRoominfoBychatroomid, qs.stringify(...args))
  }

  //根据群成员id查询群成员名称
  queryChatRoommemberBymwechatid(...args) {
    return this.axiosIns.post(this.jwtConfig.queryChatRoommemberBymwechatid, qs.stringify(...args))
  }
  //根据群号修改群参数
  updatebychatroomid(...args) {
    return this.axiosIns.post(this.jwtConfig.updatebychatroomid, qs.stringify(...args))
  }

  //查询群发产品的群
  groupconfigAll(...args) {
    return this.axiosIns.post(this.jwtConfig.groupconfigAll, qs.stringify(...args))
  }

  //查询群是否为监听群
  querychatroombytype(...args) {
    return this.axiosIns.post(this.jwtConfig.querychatroombytype, qs.stringify(...args))
  }

  //根据群号查找群成员
  findChatRoomMemberBychatroomid(...args) {
    return this.axiosIns.post(this.jwtConfig.findChatRoomMemberBychatroomid, qs.stringify(...args))
  }

  //设置微信群产品群
  chatroomadd(...args) {
    return this.axiosIns.post(this.jwtConfig.chatroomadd, qs.stringify(...args))
  }

  //更新产品群类别
  chatroomtaskupdate(...args) {
    return this.axiosIns.post(this.jwtConfig.chatroomtaskupdate, qs.stringify(...args))
  }
  //根据id删除产品群
  chatroomtaskdeletebyid(...args) {
    return this.axiosIns.post(this.jwtConfig.chatroomtaskdeletebyid, qs.stringify(...args))
  }
  //添加产品库
  tbcommodityAdd(...args) {
    return this.axiosIns.post(this.jwtConfig.tbcommodityadd, qs.stringify(...args))
  }
  //查询产品库
  tbcommodityQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.tbcommodityQuery, qs.stringify(...args))
  }

  //删除产品库
  deletebygoodsid(...args) {
    return this.axiosIns.post(this.jwtConfig.deletebygoodsid, qs.stringify(...args))
  }
  //查询微信活码
  querywechatqrcode(...args) {
    return this.axiosIns.post(this.jwtConfig.querywechatqrcode, qs.stringify(...args))
  }
  //查询推广渠道类型
  queryChannelByCid(...args) {
    return this.axiosIns.post(this.jwtConfig.queryChannelByCid, qs.stringify(...args))
  }
  //修改推广用户信息
  updatebystatus(...args) {
    return this.axiosIns.post(this.jwtConfig.updatebystatus, qs.stringify(...args))
  }
  //生成物料
  querymaterialadd(...args) {
    return this.axiosIns.post(this.jwtConfig.querymaterialadd, qs.stringify(...args))
  }
  //物料查询
  querymaterialall(...args) {
    return this.axiosIns.post(this.jwtConfig.querymaterialall, qs.stringify(...args))
  }
  //根据微信号跟好友id查询好友信息/或者群信息
  findContactinfoByWechatidFriendid(...args) {
    return this.axiosIns.post(this.jwtConfig.findContactinfoByWechatidFriendid, qs.stringify(...args))
  }
  //查询推广员列表
  querypromoter(...args) {
    return this.axiosIns.post(this.jwtConfig.querypromoter, qs.stringify(...args))
  }
  //查询公司部门
  querydepartmentbycid(...args) {
    return this.axiosIns.post(this.jwtConfig.querydepartmentbycid, qs.stringify(...args))
  }
  //根据ID删除部门
  deldepartmentbyid(...args) {
    return this.axiosIns.post(this.jwtConfig.deldepartmentbyid, qs.stringify(...args))
  }
  //新增部门
  adddepartment(...args) {
    return this.axiosIns.post(this.jwtConfig.adddepartment, qs.stringify(...args))
  }
  //新增帐号
  addaccount(...args) {
    return this.axiosIns.post(this.jwtConfig.addaccount, qs.stringify(...args))
  }
  //查询员工
  queryAccountsbyCid(...args) {
    return this.axiosIns.post(this.jwtConfig.queryAccountsbyCid, qs.stringify(...args))
  }
  //根据ID删除员工帐号
  delAccountsbyid(...args) {
    return this.axiosIns.post(this.jwtConfig.delAccountsbyid, qs.stringify(...args))
  }
  //插入电话号码到用户池
  addphonenum(...args) {
    return this.axiosIns.post(this.jwtConfig.addphonenum, qs.stringify(...args))
  }
  //查询标签对应的用户池有多少人
  queryphonetagnum(...args) {
    return this.axiosIns.post(this.jwtConfig.queryphonetagnum, qs.stringify(...args))
  }

  //添加自动加好友任务
  addfriendtask(...args) {
    return this.axiosIns.post(this.jwtConfig.addfriendtask, qs.stringify(...args))
  }
  //查看自动加粉任务
  GetAutoFriendAddTaskList(...args) {
    return this.axiosIns.post(this.jwtConfig.GetAutoFriendAddTaskList, qs.stringify(...args))
  }
  //加粉日志
  AddfriendLog(...args) {
    return this.axiosIns.post(this.jwtConfig.AddfriendLog, qs.stringify(...args))
  }

  //活动活码添加
  ActivityQrcodeAdd(...args) {
    return this.axiosIns.post(this.jwtConfig.ActivityQrcodeAdd, qs.stringify(...args))
  }

  //活动活码查询所有
  queryActivityQrcode(...args) {
    return this.axiosIns.post(this.jwtConfig.queryActivityQrcode, qs.stringify(...args))
  }
  //查询阿里妈妈帐号配置
  queryAlimamapageList(...args) {
    return this.axiosIns.post(this.jwtConfig.queryAlimamapageList, qs.stringify(...args))
  }
  //添加阿里妈妈帐号
  AlimamaInsert(...args) {
    return this.axiosIns.post(this.jwtConfig.AlimamaInsert, qs.stringify(...args))
  }

  //修改阿里妈妈帐号
  Alimamaupdate(...args) {
    return this.axiosIns.post(this.jwtConfig.Alimamaupdate, qs.stringify(...args))
  }

  //淘宝订单查询
  QuerytbOrders(...args) {
    return this.axiosIns.post(this.jwtConfig.QuerytbOrders, qs.stringify(...args))
  }
  //京东帐号插入
  JdInsert(...args) {
    return this.axiosIns.post(this.jwtConfig.JdInsert, qs.stringify(...args))
  }
  //京东帐号查询
  queryJdkeysByCid(...args) {
    return this.axiosIns.post(this.jwtConfig.queryJdkeysByCid, qs.stringify(...args))
  }
  //查询京东订单
  QueryjdOrders(...args) {
    return this.axiosIns.post(this.jwtConfig.QueryjdOrders, qs.stringify(...args))
  }

  //查询抖音订单
  QuerydouyinOrders(...args) {
    return this.axiosIns.post(this.jwtConfig.QuerydouyinOrders, qs.stringify(...args))
  }


  //查询美团订单
  QuerymeituanOrders(...args) {
    return this.axiosIns.post(this.jwtConfig.QuerymeituanOrders, qs.stringify(...args))
  }
  //查询阿里妈妈帐号
  queryAlimamaAccountByCid(...args) {
    return this.axiosIns.post(this.jwtConfig.queryAlimamaAccountByCid, qs.stringify(...args))
  }
  //电影票订单查询
  QuerymoiveOrders(...args) {
    return this.axiosIns.post(this.jwtConfig.QuerymoiveOrders, qs.stringify(...args))
  }
  //查询返利配置信息
  queryRebateConfigByCid(...args) {
    return this.axiosIns.post(this.jwtConfig.queryRebateConfigByCid, qs.stringify(...args))
  }

  //插入返利配置
  RebateConfigInsert(...args) {
    return this.axiosIns.post(this.jwtConfig.RebateConfigInsert, qs.stringify(...args))
  }

  //返利配置修改
  RebateConfigUpdate(...args) {
    return this.axiosIns.post(this.jwtConfig.RebateConfigUpdate, qs.stringify(...args))
  }
  //插入返利小程序配置
  RebateConfigInsertWeapp(...args) {
    return this.axiosIns.post(this.jwtConfig.RebateConfigInsertweapp, qs.stringify(...args))
  }

  //返利配置小程序修改
  RebateConfigUpdateWeapp(...args) {
    return this.axiosIns.post(this.jwtConfig.RebateConfigupdatebyweapp, qs.stringify(...args))
  }
  //生成文章内容
  addcontent(...args) {
    return this.axiosIns.post(this.jwtConfig.addcontent, qs.stringify(...args))
  }
  //发布文章到草稿箱
  addarticle(...args) {
    return this.axiosIns.post(this.jwtConfig.addarticle, qs.stringify(...args))
  }
  //获取模版内容
  templatebycid(...args) {
    return this.axiosIns.post(this.jwtConfig.templatebycid, qs.stringify(...args))
  }
  //获取渠道活码列表
  findqrcodebycid(...args) {
    return this.axiosIns.post(this.jwtConfig.findqrcodebycid, qs.stringify(...args))
  }
  //发布文章
  articlesubmit(...args) {
    return this.axiosIns.post(this.jwtConfig.articlesubmit, qs.stringify(...args))
  }
  //推送预览文章
  PreviewMessage(...args) {
    return this.axiosIns.post(this.jwtConfig.PreviewMessage, qs.stringify(...args))
  }
  //今日扫码加粉数据
  queryscodebytoday(...args) {
    return this.axiosIns.post(this.jwtConfig.queryscodebytoday, qs.stringify(...args))
  }
  //定义一个月的渠道加粉数据
  querymonthbydate(...args) {
    return this.axiosIns.post(this.jwtConfig.querymonthbydate, qs.stringify(...args))
  }
  //一个月的扫码加粉数据
  queryscodebymonth(...args) {
    return this.axiosIns.post(this.jwtConfig.queryscodebymonth, qs.stringify(...args))
  }
  //近30的各渠道加粉数据
  querychannlbymonth(...args) {
    return this.axiosIns.post(this.jwtConfig.querychannlbymonth, qs.stringify(...args))
  }
  //本月活动渠道加粉数据
  queryActivitybymonth(...args) {
    return this.axiosIns.post(this.jwtConfig.queryActivitybymonth, qs.stringify(...args))
  }
  //图片上传
  picInsert(...args) {
    return this.axiosIns.post(this.jwtConfig.picInsert, qs.stringify(...args))
  }
  //素材库图片列表
  picpageList(...args) {
    return this.axiosIns.post(this.jwtConfig.picpageList, qs.stringify(...args))
  }
  //删除素材库图片
  fileDel(...args) {
    return this.axiosIns.post(this.jwtConfig.fileDel, qs.stringify(...args))
  }
  //链接素材上传
  linkInsert(...args) {
    return this.axiosIns.post(this.jwtConfig.linkInsert, qs.stringify(...args))
  }
  //连接素材列表
  linkpageList(...args) {
    return this.axiosIns.post(this.jwtConfig.linkpageList, qs.stringify(...args))
  }

  //配置列表插入
  AutoTaskUpdate(...args) {
    return this.axiosIns.post(this.jwtConfig.AutoTaskUpdate, qs.stringify(...args))
  }
  //根据部门配置列表查询
  AutoTaskDetailList(...args) {
    return this.axiosIns.post(this.jwtConfig.AutoTaskDetailList, qs.stringify(...args))
  }
  //返利佣金配置插入
  RebateConfigInsertyongjin(...args) {
    return this.axiosIns.post(this.jwtConfig.RebateConfigInsertyongjin, qs.stringify(...args))
  }
  //返利佣金配置修改
  RebateConfigupdatebyyongjin(...args) {
    return this.axiosIns.post(this.jwtConfig.RebateConfigupdatebyyongjin, qs.stringify(...args))
  }

  //微信支付配置插入
  RebateConfiginsertwxpay(...args) {
    return this.axiosIns.post(this.jwtConfig.RebateConfiginsertwxpay, qs.stringify(...args))
  }
  //微信支付配置修改
  RebateConfigupdatebywxpay(...args) {
    return this.axiosIns.post(this.jwtConfig.RebateConfigupdatebywxpay, qs.stringify(...args))
  }
  //企业支付到零钱
  entPay(...args) {
    return this.axiosIns.post(this.jwtConfig.entPay, qs.stringify(...args))
  }
  //提现列表
  WxpaypageList(...args) {
    return this.axiosIns.post(this.jwtConfig.WxpaypageList, qs.stringify(...args))
  }
  //根据订单号查询
  findpaycashByorderid(...args) {
    return this.axiosIns.post(this.jwtConfig.findpaycashByorderid, qs.stringify(...args))
  }
  //根据订单状态查询
  findpaycashBystatus(...args) {
    return this.axiosIns.post(this.jwtConfig.findpaycashBystatus, qs.stringify(...args))
  }
  //根据提现人查询提现列表
  findpaycashByfriendid(...args) {
    return this.axiosIns.post(this.jwtConfig.findpaycashByfriendid, qs.stringify(...args))
  }
  //提交提现请求
  paycash(...args) {
    return this.axiosIns.post(this.jwtConfig.paycash, qs.stringify(...args))
  }
  //驳回提现请求
  payrefuse(...args) {
    return this.axiosIns.post(this.jwtConfig.payrefuse, qs.stringify(...args))
  }
  //资金明细列表
  FundRecordspageList(...args) {
    return this.axiosIns.post(this.jwtConfig.FundRecordspageList, qs.stringify(...args))
  }
  //标签
  queryTagByCidType(...args) {
    return this.axiosIns.post(this.jwtConfig.queryTagByCidType, qs.stringify(...args))
  }
  //关键字查询列表
  keyWordspageList(...args) {
    return this.axiosIns.post(this.jwtConfig.keyWordspageList, qs.stringify(...args))
  }
  //关键字添加
  keyWordsadd(...args) {
    return this.axiosIns.post(this.jwtConfig.keyWordsadd, qs.stringify(...args))
  }
  //关键字修改
  keyWordsupdate(...args) {
    return this.axiosIns.post(this.jwtConfig.keyWordsupdate, qs.stringify(...args))
  }
  //关键字删除
  keyWordsdeletes(...args) {
    return this.axiosIns.post(this.jwtConfig.keyWordsdeletes, qs.stringify(...args))
  }
  //查询关键字
  querykeyword(...args) {
    return this.axiosIns.post(this.jwtConfig.querykeyword, qs.stringify(...args))
  }
  //插入监听群
  Addmonitorchatroom(...args) {
    return this.axiosIns.post(this.jwtConfig.Addmonitorchatroom, qs.stringify(...args))
  }
  //群发任务
  AiSendInsert(...args) {
    return this.axiosIns.post(this.jwtConfig.AiSendInsert, qs.stringify(...args))
  }
  //聚推客订单
  QueryjutuikeOrders(...args) {
    return this.axiosIns.post(this.jwtConfig.QueryjutuikeOrders, qs.stringify(...args))
  }
  //产品群列表
  selectbychatroom(...args) {
    return this.axiosIns.post(this.jwtConfig.selectbychatroom, qs.stringify(...args))
  }

  //查询订单是否提交过
  querypayOrder(...args) {
    return this.axiosIns.post(this.jwtConfig.querypayOrder, qs.stringify(...args))
  }
  //微信URLScheme
  Wxscheme(...args) {
    return this.axiosIns.post(this.jwtConfig.Wxscheme, qs.stringify(...args))
  }
  //订单统计
  querycountorder(...args) {
    return this.axiosIns.post(this.jwtConfig.querycountorder, qs.stringify(...args))
  }
  //退群
  quitchatroom(...args) {
    return this.axiosIns.post(this.jwtConfig.quitchatroom, qs.stringify(...args))
  }

  findChatRoominfoByciddid(...args) {
    return this.axiosIns.post(this.jwtConfig.findChatRoominfoByciddid, qs.stringify(...args))
  }
  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

}
