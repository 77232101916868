import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'
import { baseUrl, baseUrlDev } from '../../../app.config'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
const { jwt } = useJwt(axios, {})

if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = baseUrlDev
} else if (process.env.NODE_ENV === 'debug') {
    axios.defaults.baseURL = ''
} else if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = baseUrl
}
export default jwt
