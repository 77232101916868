export default {
  // Endpoints
  loginEndpoint: '/user/login',
  queryDevicesAll: '/admin/device/pageList',
  queryDevices: '/user/device/pageList',
  deleteDevices: '/user/device/deletes',
  findDeviceStateInfoByid: '/user/device/findDeviceStateInfoByid',
  queryByCid: '/user/department/queryByCid',
  getAllAccountInfoByTypeCidDid: '/user/account/getAllAccountInfoByTypeCidDid',
  updateDevice: '/user/device/update',
  addDevice: '/user/device/add',
  queryFriendsAll: '/user/friends/pageList',
  queryChatRoomAll: '/chatroommanger/pageList',
  queryChatRoominfoBychatroomid: '/chatroommanger/queryChatRoominfoBychatroomid',
  queryChatRoommemberBymwechatid: '/chatroommanger/queryChatRoommemberBymwechatid',
  updatebychatroomid: '/chatroommanger/updatebychatroomid',
  querychatroombytype: '/chatroommanger/querychatroombytype',
  findChatRoomMemberBychatroomid: '/chatroommanger/findChatRoomMemberBychatroomid',
  groupconfigAll: '/group/config/pageList',
  selectbychatroom: '/rebate/selectbychatroom',
  chatroomadd: '/group/config/add',
  chatroomtaskupdate: '/group/config/update',
  chatroomtaskdeletebyid: '/group/config/deletebyid',
  tbcommodityadd: '/rebate/tbcommodityadd',
  tbcommodityQuery: '/rebate/pageList',
  friendquery: '/phone/friendquery',
  deletebygoodsid: '/rebate/deletebygoodsid',
  findContactinfoByWechatidFriendid: '/user/friends/findContactinfoByWechatidFriendid',
  querymaterialall: '/material/pageList',
  querymaterialadd: '/material/insert',
  querywechatqrcode: '/wx/wechatqrcode',
  queryChannelByCid: '/wx/queryChannelByCid',
  updatebystatus: '/wx/updatebystatus',
  queryFriendid: '/wx/findContactinfoByfriendid',
  Wxscheme: '/wx/Wxscheme',
  querypromoter: '/promoter/pageList',
  querydepartmentbycid: '/user/department/queryByCid',
  deldepartmentbyid: '/user/department/deletes',
  adddepartment: '/user/department/add',
  addaccount: '/user/account/add',
  queryAccountsbyCid: '/user/account/queryByCid',
  delAccountsbyid: '/user/account/deletes',
  addphonenum: '/phone/addPhonenum',
  queryphonetagnum: '/phone/queryphonebytagnum',
  addfriendtask: '/phone/addfriendtask',
  GetAutoFriendAddTaskList: '/pc/GetAutoFriendAddTaskList',
  AddfriendLog: '/phone/queryphonebylist',
  ActivityQrcodeAdd: '/ActivityQrcode/insert',
  queryActivityQrcode: '/ActivityQrcode/pageList',
  queryAlimamapageList: '/rebate/AlimamapageList',
  AlimamaInsert: '/rebate/AlimamaInsert',
  Alimamaupdate: '/rebate/Alimamaupdate',
  QuerytbOrders: '/rebate/QuerytbOrders',
  QuerydouyinOrders: '/rebate/QuerydouyinOrders',
  JdInsert: '/rebate/JdInsert',
  queryJdkeysByCid: '/rebate/queryJdkeysByCid',
  QueryjdOrders: '/rebate/QueryjdOrders',
  queryAlimamaAccountByCid: '/rebate/queryAlimamaAccountByCid',
  queryRebateConfigByCid: '/rebate/queryRebateConfigByCid',
  RebateConfigInsert: '/rebate/RebateConfigInsert',
  RebateConfigUpdate: '/rebate/RebateConfigUpdate',
  RebateConfigupdatebyweapp: '/rebate/RebateConfigupdatebyweapp',
  RebateConfigInsertweapp: '/rebate/RebateConfigInsertweapp',
  templatebycid: '/rebate/querytemplatebycid',
  QuerymoiveOrders: '/rebate/QuerymoiveOrders',
  QuerymeituanOrders: '/rebate/QuerymeituanOrders',
  addcontent: '/gzh/addcontent',
  addarticle: '/rebate/addarticle',
  findqrcodebycid: '/ActivityQrcode/findqrcodebycid',
  articlesubmit: '/rebate/submit',
  PreviewMessage: '/rebate/PreviewMessage',
  queryscodebytoday: '/analysis/queryscodebytoday',
  querymonthbydate: '/analysis/querymonthbydate',
  queryscodebymonth: '/analysis/queryscodebymonth',
  querychannlbymonth: '/analysis/querychannlbymonth',
  queryActivitybymonth: '/analysis/queryActivitybymonth',
  picInsert: '/sucai/picInsert',
  picpageList: '/sucai/picpageList',
  fileDel: '/sucai/fileDel',
  linkInsert: '/sucai/linkInsert',
  linkpageList: '/sucai/linkpageList',
  AutoTaskDetailList: '/pc/AutoTaskDetailList',
  AutoTaskUpdate: '/pc/AutoTaskUpdate',
  RebateConfigupdatebyyongjin: '/rebate/RebateConfigupdatebyyongjin',
  RebateConfigInsertyongjin: '/rebate/RebateConfigInsertyongjin',
  RebateConfiginsertwxpay: '/rebate/RebateConfiginsertwxpay',
  RebateConfigupdatebywxpay: '/rebate/RebateConfigupdatebywxpay',
  entPay: '/wxpay/entPay',
  WxpaypageList: '/wxpay/pageList',
  findpaycashByorderid: '/wxpay/findpaycashByorderid',
  findpaycashBystatus: '/wxpay/findpaycashBystatus',
  findpaycashByfriendid: '/wxpay/findpaycashByfriendid',
  payrefuse: '/wxpay/payrefuse',
  paycash: '/wx/wxpay',
  FundRecordspageList: '/FundRecords/pageList',
  queryTagByCidType: '/user/commontag/queryTagByCidType',
  keyWordspageList: '/KeyWords/pageList',
  keyWordsadd: '/KeyWords/add',
  keyWordsupdate: '/KeyWords/update',
  keyWordsdeletes: '/KeyWords/deletes',
  querykeyword: '/KeyWords/querykeyword',
  Addmonitorchatroom: '/chatroommanger/Addmonitorchatroom',
  AiSendInsert: '/GroupSend/AiSendInsert',
  QueryjutuikeOrders: '/rebate/QueryjutuikeOrders',
  querypayOrder: '/wx/querypayOrder',
  querycountorder: '/rd/querycountorder',
  quitchatroom: '/chatroommanger/quitchatroom',
  findChatRoominfoByciddid: '/user/chatroom/findChatRoominfoByciddid',
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
