import Vue from 'vue'

// initial state
const state = {
  currentNav: 'newFriend', // 工具大全当前的页面 默认是微信好友请求
  weChatIds: {}, // 工具大全每个页面所选的微信id
  searchResult: {}, // 号码、微信号的查询结果
  phoneState: {} // 手机状态
}

// getters
const getters = {
  currentWeChatId: (state) => {
    const cn = state.currentNav
    return state.weChatIds[cn] ? state.weChatIds[cn] : ''
  }, // 当前页面选择的微信
  phoneState: (state) => state.phoneState
}

// actions
const actions = {}

// mutations
const mutations = {
  // 设置tools当前的页面
  SET_CURRENT_NAV: (state, nav) => {
    state.currentNav = nav
  },
  // 设置每个页面 选择的wxid
  SET_CURRENT_WECHAT_IDS: (state, msg) => {
    Vue.set(state.weChatIds, msg.nav, msg.weChatId)
  },
  // 设置号码查微信的结果
  SET_SEARCH_RESULT: (state, searchData) => {
    state.searchResult = searchData
  },
  // 重置手机状态
  RESET_PHONE_STATE: (state) => {
    state.phoneState = {}
  },
  // 设置手机状态
  SET_PHONE_STATE: (state, data) => {
    Vue.set(state.phoneState, data.WeChatId, data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
