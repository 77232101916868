// Modules
import ecommerceStoreModule from "@/views/apps/e-commerce/eCommerceStoreModule";
import Vue from "vue";
import Vuex from "vuex";
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import circles from "./modules/circles";
import conversation from "./modules/conversation";
import indexDB from "./modules/indexDB";
import nedb from "./modules/nedb";
import tools from "./modules/tools";
import websocket from "./modules/websocket";
// state
const state = {
  bigScreen: true, // 当前的开发环境
  currentMode: "web", // 当前的开发环境
  webSocketState: 0, // websocket状态
  webSocketToken: "", // wensocket token
  currentUser: {}, // 当前登录的用户的信息
  loginInfo: {}, // 登录信息，自动登录用到 
  clientOrServer: "client", // client客户端 server管理端
  adminInfo: {}, // 管理员信息 
  httpToken: "", // 管理端返回的token

  taskTimeDialogCircle: false, // 显示朋友圈定时任务
  currentNavs: {
    friendCircle: "circleSend",
    groupAssistant: "groupSend",
    tools: "newFriend",
  }, // 每个页面选中的nav
};

const actions = {};

// getters
const getters = {
  actionUrl: () => process.env.VUE_APP_UPLOAD_URL, // element-ui 上传的action地址
  currentUser: (state) => state.currentUser,
  userId: (state) =>
    state.currentUser.SupplierId * 10000 + state.currentUser.UnionId || "",
  loginInfo: (state) => state.loginInfo,
  taskTimeDialogCircle: (state) => state.taskTimeDialogCircle,
  currentNavs: (state) => state.currentNavs,
  superAdmin: (state) => {
    return state.adminInfo.type && state.adminInfo.type === -1;
  },
};

// mutations
const mutations = {
  // 切换页面大小
  SET_BIG_SCREEN: (state) => {
    state.bigScreen = !state.bigScreen;
  },
  // pc还是web
  SET_CURRENT_MODE: (state, mode) => {
    state.currentMode = mode;
  },
  // 设置websocketState
  SET_WEB_SOCKET_STATE(state, wsState) {
    state.webSocketState = wsState;
  },
  // 设置ws token
  SET_WEBSOCKET_TOKEN: (state, wsToken) => {
    state.webSocketToken = wsToken;
  },
  // 设置http token
  SET_HTTP_TOKEN: (state, hToken) => {
    state.httpToken = hToken;
  },
  // 设置登录的信息
  SET_LOGIN_INFO: (state, loginInfo) => {
    state.loginInfo = loginInfo;
  },
  // 设置客户端还是管理端
  SET_CLIENT_OR_SERVER: (state, flagStr) => {
    state.clientOrServer = flagStr;
  },
  // 设置管理员信息
  SET_ADMIN_INO: (state, adminInfo) => {
    state.adminInfo = adminInfo;
  },
  // 设置用户信息
  SET_CURRENT_USER: (state, userInfo) => {
    state.currentUser = userInfo;
  },
  // =============================================
  // 设置是否显示朋友圈定时任务日志
  SET_TASK_TIME_DIALOG_CIRLCE: (state, flag) => {
    state.taskTimeDialogCircle = flag;
  },
  // 设置每个topnav当前的nav
  SET_CURRENT_NAV: (state, nav) => {
    Vue.set(state.currentNavs, state.route.name, nav.navPath);
  },
};
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    circles,
    conversation,
    indexDB,
    nedb,
    tools,
    websocket,
    "app-ecommerce": ecommerceStoreModule,
  },
  state,
  getters,
  mutations,
  actions,
  strict: process.env.DEV,
});
