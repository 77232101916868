export default [
  {
    path: '/marketing/material',
    name: 'material',
    component: () => import('@/views/marketing/material.vue'),
    meta: {
      pageTitle: '推广管理',
      breadcrumb: [
        {
          text: '物料管理',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/marketing/promoterlist',
    name: 'promoterlist',
    component: () => import('@/views/marketing/promoterlist.vue'),
    meta: {
      pageTitle: '推广管理',
      breadcrumb: [
        {
          text: '推广员管理',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/marketing/activityqrcode',
    name: 'activityqrcode',
    component: () => import('@/views/marketing/activityqrcode.vue'),
    meta: {
      pageTitle: '推广管理',
      breadcrumb: [
        {
          text: '活动活码',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/GroupSend/SendCircle',
    name: 'SendCircle',
    component: () => import('@/views/GroupSend/SendCircle.vue'),
    meta: {
      pageTitle: '群发任务',
      breadcrumb: [
        {
          text: '朋友圈群发',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/GroupSend/AiSend',
    name: 'AiSend',
    component: () => import('@/views/GroupSend/AiSend.vue'),
    meta: {
      pageTitle: '群发任务',
      breadcrumb: [
        {
          text: '智能群发',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/GroupSend/KeyWordSend',
    name: 'KeyWordSend',
    component: () => import('@/views/GroupSend/KeyWordSend.vue'),
    meta: {
      pageTitle: '群发任务',
      breadcrumb: [
        {
          text: '关键字回复',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/material/PicMaterial',
    name: 'PicMaterial',
    component: () => import('@/views/material/PicMaterial.vue'),
    meta: {
      pageTitle: '素材管理',
      breadcrumb: [
        {
          text: '图片素材',
          active: true,
        },
      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/material/VideoMaterial',
    name: 'VideoMaterial',
    component: () => import('@/views/material/VideoMaterial.vue'),
    meta: {
      pageTitle: '素材管理',
      breadcrumb: [
        {
          text: '视频素材',
          active: true,
        },
      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/material/LinkMaterial',
    name: 'LinkMaterial',
    component: () => import('@/views/material/LinkMaterial.vue'),
    meta: {
      pageTitle: '素材管理',
      breadcrumb: [
        {
          text: '链接素材',
          active: true,
        },
      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/marketing/userpool',
    name: 'Userpool',
    component: () => import('@/views/marketing/userpool.vue'),
    meta: {
      pageTitle: '智能加粉',
      breadcrumb: [
        {
          text: '用户池',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  
  {
    path: '/marketing/taskmanagement',
    name: 'taskmanagement',
    component: () => import('@/views/marketing/taskmanagement.vue'),
    meta: {
      pageTitle: '智能加粉',
      breadcrumb: [
        {
          text: '加粉任务',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/marketing/addfriendlog',
    name: 'addfriendlog',
    component: () => import('@/views/marketing/addfriendlog.vue'),
    meta: {
      pageTitle: '智能加粉',
      breadcrumb: [
        {
          text: '加粉日志',
          active: true,
        },
      ],
      resource: 'ecom',
      action: 'read',
    },
  },
]
