export default [
  {
    path: '/rebate/GroupConfig',
    name: 'GroupConfig',
    component: () => import('@/views/rebate/GroupConfig.vue'),
    meta: {
      pageTitle: '返利管理',
      breadcrumb: [
        {
          text: '群发配置',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/rebate/TbCommodity',
    name: 'TbCommodity',
    component: () => import('@/views/rebate/TbCommodity.vue'),
    meta: {
      pageTitle: '返利管理',
      breadcrumb: [
        {
          text: '产品列表',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/rebate/AccountConfig',
    name: 'AccountConfig',
    component: () => import('@/views/rebate/AccountConfig.vue'),
    meta: {
      pageTitle: '返利管理',
      breadcrumb: [
        {
          text: '淘宝配置',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/rebate/TbOrders',
    name: 'TbOrders',
    component: () => import('@/views/rebate/TbOrders.vue'),
    meta: {
      pageTitle: '返利管理',
      breadcrumb: [
        {
          text: '淘宝订单',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/rebate/articleRelease',
    name: 'articleRelease',
    component: () => import('@/views/rebate/articleRelease.vue'),
    meta: {
      pageTitle: '返利管理',
      breadcrumb: [
        {
          text: '发布产品文章',
          active: true,
        },

      ],
      resource: 'ecom',
      action: 'read',
    },
  },

  {
    path: '/rebate/JdOrders',
    name: 'JdOrders',
    component: () => import('@/views/rebate/JdOrders.vue'),
    meta: {
      pageTitle: '返利管理',
      breadcrumb: [
        {
          text: '京东订单',
          active: true,
        },
      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/rebate/moiveOrders',
    name: 'moiveOrders',
    component: () => import('@/views/rebate/moiveOrders.vue'),
    meta: {
      pageTitle: '返利管理',
      breadcrumb: [
        {
          text: '电影票订单',
          active: true,
        },
      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/rebate/meituanorders',
    name: 'meituanorders',
    component: () => import('@/views/rebate/meituanorders.vue'),
    meta: {
      pageTitle: '返利管理',
      breadcrumb: [
        {
          text: '美团订单',
          active: true,
        },
      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/rebate/DyOrders',
    name: 'DyOrders',
    component: () => import('@/views/rebate/DyOrders.vue'),
    meta: {
      pageTitle: '返利管理',
      breadcrumb: [
        {
          text: '抖音订单',
          active: true,
        },
      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/rebate/jutuikeorder',
    name: 'jutuikeorder',
    component: () => import('@/views/rebate/jutuikeorder.vue'),
    meta: {
      pageTitle: '返利管理',
      breadcrumb: [
        {
          text: '吃喝玩乐订单',
          active: true,
        },
      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/CashManger/CashManger',
    name: 'CashManger',
    component: () => import('@/views/CashManger/CashManger.vue'),
    meta: {
      pageTitle: '财务管理',
      breadcrumb: [
        {
          text: '提现管理',
          active: true,
        },
      ],
      resource: 'ecom',
      action: 'read',
    },
  },
  {
    path: '/CashManger/FundRecords',
    name: 'FundRecords',
    component: () => import('@/views/CashManger/FundRecords.vue'),
    meta: {
      pageTitle: '财务管理',
      breadcrumb: [
        {
          text: '资金明细',
          active: true,
        },
      ],
      resource: 'ecom',
      action: 'read',
    },
  },
]
