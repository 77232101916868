// import Vue from 'vue'
import nedb from '@/db/nedb'

// initial state
const state = {
  commentsNotice: [], // 评论通知
  circleItem: {}, // 某评论的朋友圈信息
  allFriends: []
}

// getters
const getters = {
  // 获取评论通知
  getCommentsNotice: (state) => {
    return state.commentsNotice
  },
  // 获取某通知对应的朋友圈
  getCircleItem: (state) => {
    return state.circleItem
  },
  // 好友id-好友info map
  getAllFriendsMap: (state) => {
    const fm = {}
    for (const friend of state.allFriends) {
      fm[friend.FriendId] = friend
    }
    return fm
  }
}

// actions
const actions = {
  // 设置所有的好友列表
  SetAllFriends: ({ commit }) => {
    nedb.friends.find({}, (err, docs) => {
      if (err) return
      commit('SET_ALL_FRIENDS', docs)
    })
  }
}

// mutations
const mutations = {
  // 设置所有的好友列表
  SET_ALL_FRIENDS: (state, docs) => {
    state.allFriends = docs
  },
  // 更新评论通知
  UPDATE_COMMENTS_NOTICE: (state, comment) => {
    state.commentsNotice.push(comment)
  },
  // 删除
  DELETE_COMMENTS_NOTICE: (state, commentIndex) => {
    state.commentsNotice.splice(commentIndex, 1)
  },
  // 重置单个朋友圈数据
  RESET_CIRCLE_ITEM: (state) => {
    state.circleItem = {}
  },
  // 设置朋友圈数据
  SET_CIRCLE_ITEM: (state, circle) => {
    state.circleItem = circle
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
